import HttpService from "./http.service";

class OrderService {
  getClubs = async (id, query) => {
    const Endpoint = `/order/search-clubs/${id}/${query}`;
    return await HttpService.get(Endpoint);
  };
  getTotals = async (payload) => {
    const Endpoint = `/order/getTotals`;
    return await HttpService.post(Endpoint, payload);
  };
  getClubDetails = async (payload) => {
    const Endpoint = `/order/getClubDetails`;
    return await HttpService.post(Endpoint, payload);
  };
  getClubOrders = async (payload) => {
    const Endpoint = `/order/getClubOrders`;
    return await HttpService.post(Endpoint, payload);
  };
  fetchMembers = async (payload) => {
    const Endpoint = `/order/searchMembers`;
    return await HttpService.post(Endpoint, payload);
  };
  fetchReportData = async (payload) => {
    const Endpoint = `/order/fetchReportData`;
    return await HttpService.post(Endpoint, payload);
  };
  ChangeStatus = async (payload) => {
    const Endpoint = `/order/change-status`;
    return await HttpService.post(Endpoint, payload);
  };
  fetchNameLabelData = async (payload) => {
    const Endpoint = `/order/fetchNameLabelData`;
    return await HttpService.post(Endpoint, payload);
  };
  getCounts = async () => {
    const Endpoint = `/stats/counts`;
    return await HttpService.get(Endpoint);
  }
  
  fetchClubMembers = async (payload) => {
    const Endpoint = `/order/fetchClubMembers`;
    return await HttpService.post(Endpoint, payload);
  };
}

export default new OrderService();
