import HttpService from "./http.service";

class InviteService {

  getUrl = async (id) => {
    const Endpoint = `/invite/${id}`;
    return await HttpService.get(Endpoint);
  };

  InviteCollaborator = async (payload) => {
    const Endpoint = `/invite/collaborator`;
    return await HttpService.post(Endpoint, payload);
  };

  WarmupSms = async (payload) => {
    const Endpoint = `/invite/warmup/sms`;
    return await HttpService.post(Endpoint, payload);
  };

  SaveCollaborators = async (payload) => {
    const Endpoint = `/invite/upload/UsersList`;
    return await HttpService.post(Endpoint, payload);
  };

  UploadCollabList = async (payload) => {
    const Endpoint = `/invite/upload/collabList`;
    return await HttpService.post(Endpoint, payload);
  };

  InviteExistingCollaborators = async (payload) => {
    const Endpoint = `/invite/existing/collaborators`;
    return await HttpService.post(Endpoint, payload);
  };

  addChild = async (payload) => {
    const Endpoint = `/invite/add/child`;
    return await HttpService.post(Endpoint, payload);
  };
}

export default new InviteService();
