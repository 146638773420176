import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ChatContextProvider from "context/ChatContext";
import CallContextProvider from "context/CallContext";

import App from "App";
import { Ability } from "@casl/ability";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider, AuthContextProvider } from "context";
import { AbilityContext } from "Can";

const container = document.getElementById("root");
const root = createRoot(container);
const ability = new Ability();

root.render(
    <AbilityContext.Provider value={ability}>
      <BrowserRouter>
        <AuthContextProvider>
          <ChatContextProvider>
            <CallContextProvider>
              <MaterialUIControllerProvider>
                <App />
              </MaterialUIControllerProvider>
            </CallContextProvider>
          </ChatContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </AbilityContext.Provider>
);
