import { useState, useEffect, useCallback, useMemo, useContext } from "react";
import Divider from "@mui/material/Divider"
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
} from "context";
import useTranslation from "hooks/useTranslation";
import { ThemeContext } from "@emotion/react";

function Configurator() {
  const { t } = useTranslation();
  const [controller, dispatch] = useMaterialUIController();
  const { palette, functions, borders } = useContext(ThemeContext);
  const {
    openConfigurator,
    miniSidenav,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [disabled, setDisabled] = useState(false);
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];

  useEffect(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    //const savedMiniSidenav = localStorage.getItem("miniSidenav");
    const savedFixedNavbar = localStorage.getItem("fixedNavbar");
    const savedSidenavColor = localStorage.getItem("sidenavColor");
    const savedSidenavType = localStorage.getItem("sidenavType"); // for transparent/white sidenav

    if (savedDarkMode) setDarkMode(dispatch, JSON.parse(savedDarkMode));
    //if (savedMiniSidenav) setMiniSidenav(dispatch, JSON.parse(savedMiniSidenav));
    if (savedFixedNavbar) setFixedNavbar(dispatch, JSON.parse(savedFixedNavbar));
    if (savedSidenavColor) setSidenavColor(dispatch, savedSidenavColor);
    if (savedSidenavType === "transparent") {
      setTransparentSidenav(dispatch, true);
    } else if (savedSidenavType === "white") {
      setWhiteSidenav(dispatch, true);
    }
  }, [dispatch]);
  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    const savedConfiguratorState = localStorage.getItem("configuratorOpen");
  
    if (savedConfiguratorState !== null) {
      setOpenConfigurator(dispatch, JSON.parse(savedConfiguratorState));
    }
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = useCallback(() => {
    setOpenConfigurator(dispatch, false);
    localStorage.setItem("configuratorOpen", JSON.stringify(false));
  }, [dispatch]);

  const handleDarkMode = useCallback(() => {
    setDarkMode(dispatch, !darkMode);
    localStorage.setItem("darkMode", JSON.stringify(!darkMode));
  }, [darkMode, dispatch]);

  const handleMiniSidenav = useCallback(() => {
    setMiniSidenav(dispatch, !miniSidenav);
    //localStorage.setItem("miniSidenav", JSON.stringify(!miniSidenav));
  }, [miniSidenav, dispatch]);

  const handleFixedNavbar = useCallback(() => {
    setFixedNavbar(dispatch, !fixedNavbar);
    localStorage.setItem("fixedNavbar", JSON.stringify(!fixedNavbar));
  }, [fixedNavbar, dispatch]);

  const handleSidenavColor = useCallback((color) => {
    setSidenavColor(dispatch, color);
    localStorage.setItem("sidenavColor", color);
  }, [dispatch]);

  const handleTransparentSidenav = useCallback(() => {
    setTransparentSidenav(dispatch, true);
    setWhiteSidenav(dispatch, false);
    localStorage.setItem("sidenavType", "transparent");
  }, [dispatch]);

  const handleWhiteSidenav = useCallback(() => {
    setWhiteSidenav(dispatch, true);
    setTransparentSidenav(dispatch, false);
    localStorage.setItem("sidenavType", "white");
  }, [dispatch]);

  const handleDarkSidenav = useCallback(() => {
    setWhiteSidenav(dispatch, false);
    setTransparentSidenav(dispatch, false);
    localStorage.setItem("sidenavType", "dark");
  }, [dispatch]);

  const sidenavTypeButtonsStyles = useMemo(() => {
    // Get the theme from the MUI ThemeContext
    const { pxToRem } = functions;
    const { white, dark, background } = palette;
    const { borderWidth } = borders;
  
    return {
      height: pxToRem(39),
      background: darkMode ? background.sidenav : white.main,
      color: darkMode ? white.main : dark.main,
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
      "&:hover, &:focus": {
        background: darkMode ? background.sidenav : white.main,
        color: darkMode ? white.main : dark.main,
        border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
      },
    };
  }, [darkMode]);
  
  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = useMemo(() => {
    const { pxToRem, linearGradient } = functions;
    const { white, gradients, background } = palette;
  
    return {
      height: pxToRem(39),
      background: darkMode
        ? white.main
        : linearGradient(gradients.dark.main, gradients.dark.state),
      color: darkMode ? background.sidenav : white.main,
      "&:hover, &:focus": {
        background: darkMode
          ? white.main
          : linearGradient(gradients.dark.main, gradients.dark.state),
        color: darkMode ? background.sidenav : white.main,
      },
    };
  }, [darkMode]);

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Ekofib UI Configurator</MDTypography>
          <MDTypography variant="body2" color="text">
            {t('See our new dashboard options.')}
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox pt={0.5} pb={3} px={3}>
        <MDBox>
          <MDTypography variant="h6">Sidenav Colors</MDTypography>

          <MDBox mb={0.5}>
            {sidenavColors.map((color) => (
              <IconButton
                key={color}
                sx={({
                  borders: { borderWidth },
                  palette: { white, dark, background },
                  transitions,
                }) => ({
                  width: "24px",
                  height: "24px",
                  padding: 0,
                  border: `${borderWidth[1]} solid ${darkMode ? background.sidenav : white.main}`,
                  borderColor: () => {
                    let borderColorValue = sidenavColor === color && dark.main;

                    if (darkMode && sidenavColor === color) {
                      borderColorValue = white.main;
                    }

                    return borderColorValue;
                  },
                  transition: transitions.create("border-color", {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.shorter,
                  }),
                  backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                    linearGradient(gradients[color].main, gradients[color].state),

                  "&:not(:last-child)": {
                    mr: 1,
                  },

                  "&:hover, &:focus, &:active": {
                    borderColor: darkMode ? white.main : dark.main,
                  },
                })}
                onClick={() => handleSidenavColor(color)}
              />
            ))}
          </MDBox>
        </MDBox> 

        <MDBox mt={3} lineHeight={1}>
          <MDTypography variant="h6">{t("Sidenav Type")}</MDTypography>
          <MDTypography variant="button" color="text">
            {t("Choose between different sidenav types.")}
          </MDTypography>

          <MDBox
            sx={{
              display: "flex",
              mt: 2,
              mr: 1,
            }}
          >
            <MDButton
              color="dark"
              variant="gradient"
              onClick={handleDarkSidenav}
              disabled={disabled}
              fullWidth
              sx={
                !transparentSidenav && !whiteSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              {t("Dark")}
            </MDButton>
            <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
              <MDButton
                color="dark"
                variant="gradient"
                onClick={handleTransparentSidenav}
                disabled={disabled}
                fullWidth
                sx={
                  transparentSidenav && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
                }
              >
                {t("Transparent")}
              </MDButton>
            </MDBox>
            <MDButton
              color="dark"
              variant="gradient"
              onClick={handleWhiteSidenav}
              disabled={disabled}
              fullWidth
              sx={
                whiteSidenav && !transparentSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              {t("White")}
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          lineHeight={1}
        >
          <MDTypography variant="h6">{t("Navbar Fixed")}</MDTypography>

          <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">{t("Sidenav Mini")}</MDTypography>

          <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">{t("Light / Dark")}</MDTypography>

          <Switch checked={darkMode} onChange={handleDarkMode} />
        </MDBox>
        <Divider />
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
