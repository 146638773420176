import HttpService from "./http.service";

class AuthService {
  login = async (payload) => {
    const loginEndpoint = "login";
    return await HttpService.post(loginEndpoint, payload);
  };

  register = async (credentials) => {
    const registerEndpoint = "register";
    return await HttpService.post(registerEndpoint, credentials);
  };

  join = async (credentials) => {
    const registerEndpoint = "join";
    return await HttpService.post(registerEndpoint, credentials);
  };

  fetchData = async (credentials) => {
    const registerEndpoint = "fetch/user/data";
    return await HttpService.post(registerEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = "logout";
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = "password-forgot";
    return await HttpService.post(forgotPassword, payload);
  };

  resetPassword = async (credentials) => {
    console.log("credentials: ", credentials);
    const resetPassword = "password-reset";
    return await HttpService.post(resetPassword, credentials);
  };

  getProfile = async (id) => {
    const getProfile = "me";
    return await HttpService.post(getProfile, {id});
  };

  updateProfile = async (newInfo) => {
    const updateProfile = "me";
    return await HttpService.patch(updateProfile, newInfo);
  };

  updateProfilePicture = async (newInfo) => {
    const updateProfilePicture = "me/profile-picture";
    return await HttpService.patch(updateProfilePicture, newInfo);
  };

  changePassword = async (id, payload) => {
    const changePasswordEndpoint = "change-password";
    return await HttpService.post(changePasswordEndpoint, { id,...payload });
  };
}

export default new AuthService();
